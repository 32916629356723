
import { httpClient } from '~/utils/axios'
import _ from 'lodash';

export default {
  data() {
    return {
      showSelectModal: false,
      showLoginModal: false,

      isBeforeRequest: true,
      client_uid: null,
      password: null,
      confirm_token: null,
      showPassword: false,
    }
  },
  methods: {
    open(){
      let account = {
        company_uid: this.$store.state.auth.company.uid,
        company_name: this.$store.state.auth.company.name,
        client_uid: this.$store.state.auth.client.uid,
        client_name: this.$store.state.auth.client.name,
        auth_token: this.$store.state.auth.authToken,
      }
      this.$store.commit("devise/addAuthList", account)
      this.showSelectModal = true
    },
    accountChange(account){
      let self = this
      if(account.client_uid === this.$store.state.auth.client.uid) return
      self.$store.commit('auth/setAuthToken', account.auth_token)
      setTimeout(()=>{
        window.location.reload()
      }, 100)
    },
    accountLogout(account){
      let self = this
      self.$bvModal.msgBoxConfirm(`${account.client_name}(${account.company_name})からログアウトしてよろしいですか？`, {
        okTitle: 'はい',
        cancelTitle: 'いいえ',
        centered: true,
      }).then(async confirm => {
        if(confirm){
          this.$store.commit("devise/deleteAuthList", account)
        }
      })
    },
    accountAddStart(){
      if(this.$store.state.devise.authLists.length >= 10){
        this.$store.commit("alert/setError", "同時ログインの上限に達しているため、アカウントを減らしてください")
        return
      }
      this.isBeforeRequest = true
      this.client_uid = null
      this.password = null
      this.confirm_token = null
      this.showPassword = false
      this.showLoginModal = true
    },
    async accountAdd(){
      let self = this
      if(this.client_uid === this.$store.state.auth.client.uid){
        self.$store.commit("alert/setError", "自分へのログインはできません")
        return
      }
      await self.$refs.observer.validate().then(async result => {
        self.$nuxt.$loading.start()
        self.$store.commit("loading/start")
        await httpClient
          .post('/cli/account/login.json', {
            uid: self.client_uid,
            password: self.password,
            confirm_token: self.confirm_token,
            devise_token: self.$store.state.devise.devise_token,
          })
          .then(async (res) => {
            if (res.data.status === 'success') {
              let account = {
                company_uid: res.data.data.company.uid,
                company_name: res.data.data.company.name,
                client_uid: res.data.data.client.uid,
                client_name: res.data.data.client.name,
                auth_token: res.data.data.auth_token,
              }
              self.$store.commit("devise/addAuthList", account)
              self.$store.commit('auth/setAuthToken', res.data.data.auth_token)
              self.$store.commit('devise/setDeviseToken', res.data.data.devise_token)
              self.$store.commit('auth/setHeadquarter', res.data.data.headquarter)
              self.$store.commit('auth/setCompany', res.data.data.company)
              self.$store.commit('auth/setCalendars', res.data.data.calendars)
              self.$store.commit('auth/setClient', res.data.data.client)
              // 別途マスタ取得
              self.$store.dispatch("auth/getDashboard")
              self.$store.dispatch('auth/getClient')
              self.$store.commit("loading/stop")
              this.showSelectModal = false
              this.showLoginModal = false
              return self.$router.push('/')
            } else if(res.data.status === "devise_not_authorize"){
              self.$store.commit("loading/stop")
              self.isBeforeRequest = false
            } else {
              self.$store.commit("loading/stop")
              self.$store.commit("alert/setError", res.data.message)
            }
          })
          .finally(() => {
            this.$nuxt.$loading.finish()
          })
      });

    },
  }
}
