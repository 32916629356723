export const CommonAccountInfoModal = () => import('../../components/common/AccountInfoModal.vue' /* webpackChunkName: "components/common-account-info-modal" */).then(c => wrapFunctional(c.default || c))
export const CommonIDCard = () => import('../../components/common/IDCard.vue' /* webpackChunkName: "components/common-i-d-card" */).then(c => wrapFunctional(c.default || c))
export const CommonModalFirstContact = () => import('../../components/common/ModalFirstContact.vue' /* webpackChunkName: "components/common-modal-first-contact" */).then(c => wrapFunctional(c.default || c))
export const CommonMultiAuth = () => import('../../components/common/MultiAuth.vue' /* webpackChunkName: "components/common-multi-auth" */).then(c => wrapFunctional(c.default || c))
export const PartChartSummary = () => import('../../components/part/ChartSummary.vue' /* webpackChunkName: "components/part-chart-summary" */).then(c => wrapFunctional(c.default || c))
export const PartCurrentData = () => import('../../components/part/CurrentData.vue' /* webpackChunkName: "components/part-current-data" */).then(c => wrapFunctional(c.default || c))
export const PartIntroduction = () => import('../../components/part/Introduction.vue' /* webpackChunkName: "components/part-introduction" */).then(c => wrapFunctional(c.default || c))
export const PartMemo = () => import('../../components/part/Memo.vue' /* webpackChunkName: "components/part-memo" */).then(c => wrapFunctional(c.default || c))
export const PartPoint = () => import('../../components/part/Point.vue' /* webpackChunkName: "components/part-point" */).then(c => wrapFunctional(c.default || c))
export const PartReview = () => import('../../components/part/Review.vue' /* webpackChunkName: "components/part-review" */).then(c => wrapFunctional(c.default || c))
export const PartTicket = () => import('../../components/part/Ticket.vue' /* webpackChunkName: "components/part-ticket" */).then(c => wrapFunctional(c.default || c))
export const UtilActionBtn = () => import('../../components/util/ActionBtn.vue' /* webpackChunkName: "components/util-action-btn" */).then(c => wrapFunctional(c.default || c))
export const UtilAlert = () => import('../../components/util/Alert.vue' /* webpackChunkName: "components/util-alert" */).then(c => wrapFunctional(c.default || c))
export const UtilCard = () => import('../../components/util/Card.vue' /* webpackChunkName: "components/util-card" */).then(c => wrapFunctional(c.default || c))
export const UtilCardPayjp = () => import('../../components/util/CardPayjp.vue' /* webpackChunkName: "components/util-card-payjp" */).then(c => wrapFunctional(c.default || c))
export const UtilConfirmDialog = () => import('../../components/util/ConfirmDialog.vue' /* webpackChunkName: "components/util-confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const UtilFooterMenu = () => import('../../components/util/FooterMenu.vue' /* webpackChunkName: "components/util-footer-menu" */).then(c => wrapFunctional(c.default || c))
export const UtilGButton = () => import('../../components/util/GButton.vue' /* webpackChunkName: "components/util-g-button" */).then(c => wrapFunctional(c.default || c))
export const UtilIconAvatar = () => import('../../components/util/IconAvatar.vue' /* webpackChunkName: "components/util-icon-avatar" */).then(c => wrapFunctional(c.default || c))
export const UtilImageFileInput = () => import('../../components/util/ImageFileInput.vue' /* webpackChunkName: "components/util-image-file-input" */).then(c => wrapFunctional(c.default || c))
export const UtilImageThumbnail = () => import('../../components/util/ImageThumbnail.vue' /* webpackChunkName: "components/util-image-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const UtilLoading = () => import('../../components/util/Loading.vue' /* webpackChunkName: "components/util-loading" */).then(c => wrapFunctional(c.default || c))
export const UtilNumberInput = () => import('../../components/util/NumberInput.vue' /* webpackChunkName: "components/util-number-input" */).then(c => wrapFunctional(c.default || c))
export const UtilPageHeader = () => import('../../components/util/PageHeader.vue' /* webpackChunkName: "components/util-page-header" */).then(c => wrapFunctional(c.default || c))
export const UtilQrReader = () => import('../../components/util/QrReader.vue' /* webpackChunkName: "components/util-qr-reader" */).then(c => wrapFunctional(c.default || c))
export const UtilRating = () => import('../../components/util/Rating.vue' /* webpackChunkName: "components/util-rating" */).then(c => wrapFunctional(c.default || c))
export const UtilSmartLock = () => import('../../components/util/SmartLock.vue' /* webpackChunkName: "components/util-smart-lock" */).then(c => wrapFunctional(c.default || c))
export const UtilSmartLockModal = () => import('../../components/util/SmartLockModal.vue' /* webpackChunkName: "components/util-smart-lock-modal" */).then(c => wrapFunctional(c.default || c))
export const UtilTabs = () => import('../../components/util/Tabs.vue' /* webpackChunkName: "components/util-tabs" */).then(c => wrapFunctional(c.default || c))
export const UtilTouchInput = () => import('../../components/util/TouchInput.vue' /* webpackChunkName: "components/util-touch-input" */).then(c => wrapFunctional(c.default || c))
export const UtilUserIcon = () => import('../../components/util/UserIcon.vue' /* webpackChunkName: "components/util-user-icon" */).then(c => wrapFunctional(c.default || c))
export const CommonBodyscanDetail = () => import('../../components/common/bodyscan/Detail.vue' /* webpackChunkName: "components/common-bodyscan-detail" */).then(c => wrapFunctional(c.default || c))
export const CommonBodyscanLists = () => import('../../components/common/bodyscan/Lists.vue' /* webpackChunkName: "components/common-bodyscan-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonClPaymentLists = () => import('../../components/common/cl_payment/Lists.vue' /* webpackChunkName: "components/common-cl-payment-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTrendChart = () => import('../../components/common/client_trend/Chart.vue' /* webpackChunkName: "components/common-client-trend-chart" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTrendChartBody = () => import('../../components/common/client_trend/ChartBody.vue' /* webpackChunkName: "components/common-client-trend-chart-body" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTrendDetail = () => import('../../components/common/client_trend/Detail.vue' /* webpackChunkName: "components/common-client-trend-detail" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTrendForm = () => import('../../components/common/client_trend/Form.vue' /* webpackChunkName: "components/common-client-trend-form" */).then(c => wrapFunctional(c.default || c))
export const CommonClientTrendLists = () => import('../../components/common/client_trend/Lists.vue' /* webpackChunkName: "components/common-client-trend-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonDocList = () => import('../../components/common/doc/List.vue' /* webpackChunkName: "components/common-doc-list" */).then(c => wrapFunctional(c.default || c))
export const CommonDocLists = () => import('../../components/common/doc/Lists.vue' /* webpackChunkName: "components/common-doc-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonGoalTermDiffPart = () => import('../../components/common/goal_term/DiffPart.vue' /* webpackChunkName: "components/common-goal-term-diff-part" */).then(c => wrapFunctional(c.default || c))
export const CommonGoalTermEdit = () => import('../../components/common/goal_term/Edit.vue' /* webpackChunkName: "components/common-goal-term-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonGoalTermList = () => import('../../components/common/goal_term/List.vue' /* webpackChunkName: "components/common-goal-term-list" */).then(c => wrapFunctional(c.default || c))
export const CommonGoalTermLists = () => import('../../components/common/goal_term/Lists.vue' /* webpackChunkName: "components/common-goal-term-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonGoalTermStatusChip = () => import('../../components/common/goal_term/StatusChip.vue' /* webpackChunkName: "components/common-goal-term-status-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonBodyData = () => import('../../components/common/lesson/BodyData.vue' /* webpackChunkName: "components/common-lesson-body-data" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonChart = () => import('../../components/common/lesson/Chart.vue' /* webpackChunkName: "components/common-lesson-chart" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonChartBody = () => import('../../components/common/lesson/ChartBody.vue' /* webpackChunkName: "components/common-lesson-chart-body" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonChartMuscle = () => import('../../components/common/lesson/ChartMuscle.vue' /* webpackChunkName: "components/common-lesson-chart-muscle" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonEdit = () => import('../../components/common/lesson/Edit.vue' /* webpackChunkName: "components/common-lesson-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonEventSet = () => import('../../components/common/lesson/EventSet.vue' /* webpackChunkName: "components/common-lesson-event-set" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonEventSetHistory = () => import('../../components/common/lesson/EventSetHistory.vue' /* webpackChunkName: "components/common-lesson-event-set-history" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonList = () => import('../../components/common/lesson/List.vue' /* webpackChunkName: "components/common-lesson-list" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonLists = () => import('../../components/common/lesson/Lists.vue' /* webpackChunkName: "components/common-lesson-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonMenu = () => import('../../components/common/lesson/Menu.vue' /* webpackChunkName: "components/common-lesson-menu" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonReserve = () => import('../../components/common/lesson/Reserve.vue' /* webpackChunkName: "components/common-lesson-reserve" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonReserveResourceTr = () => import('../../components/common/lesson/ReserveResourceTr.vue' /* webpackChunkName: "components/common-lesson-reserve-resource-tr" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonReserveWeekTr = () => import('../../components/common/lesson/ReserveWeekTr.vue' /* webpackChunkName: "components/common-lesson-reserve-week-tr" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonReview = () => import('../../components/common/lesson/Review.vue' /* webpackChunkName: "components/common-lesson-review" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonReviewComment = () => import('../../components/common/lesson/ReviewComment.vue' /* webpackChunkName: "components/common-lesson-review-comment" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonStatusChip = () => import('../../components/common/lesson/StatusChip.vue' /* webpackChunkName: "components/common-lesson-status-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonTrainingEvent = () => import('../../components/common/lesson/TrainingEvent.vue' /* webpackChunkName: "components/common-lesson-training-event" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonTrainingEventSelector = () => import('../../components/common/lesson/TrainingEventSelector.vue' /* webpackChunkName: "components/common-lesson-training-event-selector" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonTrainingEvents = () => import('../../components/common/lesson/TrainingEvents.vue' /* webpackChunkName: "components/common-lesson-training-events" */).then(c => wrapFunctional(c.default || c))
export const CommonLessonTypeChip = () => import('../../components/common/lesson/TypeChip.vue' /* webpackChunkName: "components/common-lesson-type-chip" */).then(c => wrapFunctional(c.default || c))
export const CommonPointLists = () => import('../../components/common/point/Lists.vue' /* webpackChunkName: "components/common-point-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonSurveyQuestion = () => import('../../components/common/survey/Question.vue' /* webpackChunkName: "components/common-survey-question" */).then(c => wrapFunctional(c.default || c))
export const CommonTicketSetLists = () => import('../../components/common/ticket_set/Lists.vue' /* webpackChunkName: "components/common-ticket-set-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonTrainingEventDetail = () => import('../../components/common/training_event/Detail.vue' /* webpackChunkName: "components/common-training-event-detail" */).then(c => wrapFunctional(c.default || c))
export const CommonTrainingEventList = () => import('../../components/common/training_event/List.vue' /* webpackChunkName: "components/common-training-event-list" */).then(c => wrapFunctional(c.default || c))
export const CommonTrainingListEdit = () => import('../../components/common/training_list/Edit.vue' /* webpackChunkName: "components/common-training-list-edit" */).then(c => wrapFunctional(c.default || c))
export const CommonTrainingListLists = () => import('../../components/common/training_list/Lists.vue' /* webpackChunkName: "components/common-training-list-lists" */).then(c => wrapFunctional(c.default || c))
export const CommonTrainingListSelector = () => import('../../components/common/training_list/Selector.vue' /* webpackChunkName: "components/common-training-list-selector" */).then(c => wrapFunctional(c.default || c))
export const UtilDocsDocument = () => import('../../components/util/docs/Document.vue' /* webpackChunkName: "components/util-docs-document" */).then(c => wrapFunctional(c.default || c))
export const UtilDocsDocumentInputs = () => import('../../components/util/docs/DocumentInputs.vue' /* webpackChunkName: "components/util-docs-document-inputs" */).then(c => wrapFunctional(c.default || c))
export const UtilVideosVideoInput = () => import('../../components/util/videos/VideoInput.vue' /* webpackChunkName: "components/util-videos-video-input" */).then(c => wrapFunctional(c.default || c))
export const UtilVideosVideoInputs = () => import('../../components/util/videos/VideoInputs.vue' /* webpackChunkName: "components/util-videos-video-inputs" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
