
import _ from 'lodash';
import { httpClient } from '~/utils/axios'
import Common from '~/mixins/common'
import SmartLockModal from '~/components/util/SmartLockModal'

export default {
  middleware: ['authenticated'],
  mixins: [Common],
  components: {
    SmartLockModal,
  },
  props: {
  },
  data() {
    return {
      show: false,
      code: null,
    }
  },
  async mounted() {
    let self = this

  },
  computed: {
  },
  methods: {
    async open(company_id = null){
      let self = this
      if(this.company.smartlock_type === "sesame"){
        self.$store.commit("loading/start")
        await httpClient
          .post(`/cli/account/open_door.json`, {company_id: company_id})
          .then(async (res) => {
            self.$store.commit("loading/stop")
            if (res.data.status === 'success') {
              self.$store.commit("alert/setSuccess", "解錠しました")
            } else {
              self.$store.commit("alert/setError", res.data.message)
            }
          })
          .finally(() => {
            self.$store.commit("loading/stop")
          })
      }else if(this.company.smartlock_type === "remotelock"){
        this.$refs.smart_lock.open()
      }
    },
  }
}
