
import _ from 'lodash';
import Common from '~/mixins/common'
import ClientTrendForm from '~/components/common/client_trend/Form'
import IDCard from '~/components/common/IDCard'
import SmartLock from '~/components/util/SmartLock'
import LessonEdit from '~/components/common/lesson/Edit'

export default {
  middleware: ['authenticated'],
  mixins: [Common],
  components: {
    ClientTrendForm,
    IDCard,
    SmartLock,
    LessonEdit,
  },
  props: {
  },
  data() {
    return {
    }
  },
  async mounted() {
    let self = this

  },
  computed: {
  },
  methods: {
  }
}
