import _ from 'lodash';
import { dayjsWapper } from '~/utils/tool'
import { mapState } from 'vuex'
import { httpClient } from '~/utils/axios'
import { adjustColorHex } from '~/utils/tool'

export default {
  async mounted() {
    let self = this

    // ページトップへ
    document.querySelector('body').scrollTop = 0
  },
  computed: {
    ...mapState({
      // 状態管理
      userAgent: state => state.userAgent,
      isPC: state => state.devise.isPC,
      isTouchDevice: state => state.devise.isTouchDevice,

      // ログイン関連
      isLogin: state => !!state.auth.authToken,
      new_alert_size: state => state.auth.new_alert_size,
      headquarter: state => state.auth.headquarter,
      company: state => state.auth.company,
      design_datas: state => state.auth.design_datas,

      // マスタ
      courses: state => state.auth.courses,
      item_categories: state => state.auth.item_categories,
      items: state => state.auth.items,
      training_categories: state => state.auth.training_categories,
      training_events: state => state.auth.training_events,
      training_machines: state => state.auth.training_machines,
      training_muscles: state => state.auth.training_muscles,
      time_zone: state => state.auth.time_zone,
      survey: state => state.auth.survey,

      // 予約関連マスタ
      target_company: state => state.auth.target_company,
      company_groups: state => state.auth.company_groups,
      able_companies: state => state.auth.able_companies,
      calendars: state => state.auth.calendars,
      resources: state => state.auth.resources,
      menus: state => state.auth.menus,

      // 体験系
      public_headquarter: state => state.public.headquarter,
      public_company: state => state.public.company,
      public_design_datas: state => state.public.design_datas,      

      // 顧客関連
      prev_has_data_lesson: state => state.auth.prev_has_data_lesson,
      prev_has_data_client_trend: state => state.auth.prev_has_data_client_trend,
      prev_lesson: state => state.auth.prev_lesson,
      next_lesson: state => state.auth.next_lesson,
      client: state => state.auth.client,
      goal_term: state => state.auth.goal_term,
      inprogressTaskSets: state => state.auth.inprogressTaskSets,

      reload_at: state => state.event.reload_at,
    }),

    // 各種属性
    company_setting(){
      if(!this.company.settings) return null
      return this.company.settings.company_setting
    },
    // ビジター設定
    visitor_setting(){
      if(!this.headquarter.settings) return null
      return this.headquarter.settings.visitor_setting
    },
    // 顧客カスタム属性設定
    client_setting(){
      if(!this.headquarter.settings) return null
      return this.headquarter.settings.client_attrs_setting
    },
    // レッスンカスタム属性設定
    lesson_setting(){
      if(!this.headquarter.settings) return null
      return this.headquarter.settings.lesson_attrs_setting
    },
    use_taisosei(){
      return this.lesson_setting.weight.use || this.lesson_setting.fat_rate.use || this.lesson_setting.basal_metabolism.use || this.lesson_setting.bmi.use
    },
    use_size(){
      return this.lesson_setting.neck_girth_length.use || this.lesson_setting.upper_arm_girth_length.use || this.lesson_setting.bust_girth_length.use || this.lesson_setting.waist_girth_length.use || this.lesson_setting.hip_girth_length.use || this.lesson_setting.thigh_girth_length.use
    },
    use_big3(){
      return this.lesson_setting.max_weight_bench_press.use || this.lesson_setting.max_weight_squat.use || this.lesson_setting.max_weight_deadlift.use
    },
    
    // 利用権限
    can_use_online_reserve(){
      return this.company.use_online_reserve
    },
    can_use_self_training(){
      return this.headquarter.use_self_training && this.client.status === 'active' && this.client.use_self_training
    },
    can_use_logs(){
      return this.headquarter.use_life_logs && this.client.status === 'active' && this.client.use_logs
    },
    can_use_payment(){
      return this.company.use_payment && this.company.allow_client_buy
    },
    can_use_remote_lock(){
      return this.company.use_smartlock && this.client.can_open_door
    },
    can_use_checkin(){
      return this.headquarter.use_checkin
    },

    // design
    primaryGradationBgColor(){
      return `linear-gradient(93.1deg, ${adjustColorHex(this.design_datas.primary_color)} 0%, ${this.design_datas.primary_color} 100%)`
    },
  },
  methods: {
    generateUUID(){
      return window.crypto.randomUUID();
    },
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
    limitDateOver(limit_date){
      if(!limit_date) return false
      let today = dayjsWapper()
      return today.isAfter(dayjsWapper(limit_date))
    },
    autoLink(text) {
      return _.isString(text) ? text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1' target='_blank'>$1</a>") : '';
    },
    copyToClipborad(text){
      this.$copyText(text).then(function (e) {
        window.storeCtl.commit("alert/setSuccess", "コピーしました")
      }, function (e) {
        alert('Can not copy')
      })      
    },

    //　FIXME: 初期化で必要デザイン設定
    async updateDesign(isPublic = false){
      let design_datas = isPublic ? this.$store.state.public.design_datas : this.$store.state.auth.design_datas
      if (design_datas) {
        const style = document.createElement('style');
        style.type = 'text/css';
        let innerHTML = null 
        innerHTML += `.custom-control-input:checked ~ .custom-control-label::before { border-color: ${design_datas.primary_color} !important; background-color: ${design_datas.primary_color} !important;}`;
        innerHTML += `.modal_content_wrapper{ background-color: ${design_datas.body_color} !important; }`;
        innerHTML += `.card-review{ border-color: ${design_datas.primary_color} !important; }`;
        innerHTML += `.modal_content_wrapper{ background-color: ${design_datas.body_color} !important; }`;
        innerHTML += `.body_text_color{ color: ${design_datas.body_text_color} !important; }`;
        innerHTML += `.primary_color{ color: ${design_datas.primary_color} !important; }`;
        innerHTML += `.page-item .page-link{ color: ${design_datas.primary_color} !important;}`;
        innerHTML += `.page-item.active .page-link{ color: #FFFF !important; background-color: ${design_datas.primary_color} !important; border-color: ${design_datas.primary_color} !important;}`;
        style.innerHTML = innerHTML
        document.head.appendChild(style);
      }
    },

    async getCompanyWithPublic(campaign_uid){
      let self = this
      await httpClient
      .get(`/cli/public/company.json?campaign_uid=${campaign_uid}`)
      .then(async (res) => {
        if (res.data.status === 'success') {
          self.campaign_name = res.data.data.campaign_name
          self.$store.commit("public/setHeadquarter", res.data.data.headquarter)
          self.$store.commit("public/setCompany", res.data.data.company)

          // survey
          let survey = res.data.data.survey
          let survey_questions = res.data.data.survey_questions
          if(survey) survey.survey_questions = _.sortBy(survey_questions, "sort")
          self.$store.commit("public/setSurvey", survey)
        } else {
          self.$store.commit("alert/setError", res.data.message)
        }
      })
    },
  }
}
